import React from "react";
import { useTranslation, useI18next } from "gatsby-plugin-react-i18next";

import "../../css/SectionSubMenu.css";
import WrapperLink from "./WrapperLink";

/**
 *
 * @param string title
 * @param array menu : List of menu object to display - Object {string code, string title, string translate}
 * @param string [codeExclude] : Menu to exclude in the list
 * @returns
 */

const SectionSubMenu = props => {
  const { t } = useTranslation();
  const { language } = useI18next();

  const codeExclude = props.codeExclude ?? "";
  const menus = props.menus.filter(menu => {
    if (menu.code === codeExclude) return false;
    return true;
  });

  return (
    <section className="abel pb5">
      <div className="section-page-submenu">
        <hr className="mv5" />

        <div className="cf">
          <div className="fl w-100 w-25-ns title pa3">{props.title}</div>
          <div className="fl w-100 w-75-ns">
            {menus.map(menu => {
              const menuLink = ("fr" !== language ? `/${language}` : "") + menu.link;
              return (
                <WrapperLink key={menu.code} href={menuLink}>
                  <div className="fl w-100 w-50-m w-third-l pa3">{t(menu.translate)}</div>
                </WrapperLink>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionSubMenu;
