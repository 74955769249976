import React from "react";
import { Helmet } from "gatsby-plugin-react-i18next";
// import { useStaticQuery, graphql } from "gatsby";

const Seo = ({ description, title }) => {
  const metaDescription = description || "";

  return (
    <Helmet
      title={`SR - ${title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription
        },
        {
          property: `og:title`,
          content: title
        },
        {
          property: `og:description`,
          content: metaDescription
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          name: `twitter:card`,
          content: `summary`
        },
        {
          name: `twitter:creator`,
          content: "Special Request"
        },
        {
          name: `twitter:title`,
          content: title
        },
        {
          name: `twitter:description`,
          content: metaDescription
        }
      ]}
    />
  );
};

export default Seo;
