import React from "react";

/**
 *
 * Displays an image in full width
 * @param string nameImageHD - path to image HD in folder /static/images/
 * @param string [nameImageSD] - path to image SD in folder /static/images/ ( 375 width )
 * @param string alt - alt for tag img
 * @returns
 */
const SectionImage = props => {
  const nameImageHD = props.imageHD;
  const nameImageSD = props.imageSD ?? nameImageHD;
  let srcset = nameImageSD !== "" ? `/images/${nameImageSD} 375w, ` : "";
  srcset += `/images/${nameImageHD}`;
  return (
    <section>
      {nameImageHD && <img className="w-100" srcSet={srcset} src={`/images/${nameImageHD}`} alt={props.alt} />}
    </section>
  );
};

export default SectionImage;
