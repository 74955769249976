import React from "react";

/**
 * Allows you to define a section to display its content in the width of a page
 * @param string [mode] - white or dark
 * @param string [className]
 * @returns
 */
const SectionTextPage = props => {
  const modeSection = props.mode ?? "white";

  return (
    <section
      className={`section-page-text abel ${modeSection === "dark" ? "sectionDark" : "sectionWhite"} 
      ${props.className ?? ""}`}
      style={props.style}
    >
      <div className={`section-page-text-ctn`}>{props.children}</div>
    </section>
  );
};

export default SectionTextPage;
