import React from "react";

import "../../css/SectionImagePlayer.css";
import WrapperLink from "./WrapperLink";

/**
 *
 * Displays an image in the width of the page (not 100% of the screen) - Allows to have a link to a video which displays a "Play" icon in the middle of the image
 * @param string [mode] - white or dark ( white by default )
 * @param string imageHD - path to image HD in folder /static/images/
 * @param string [imageSD] - path to image SD in folder /static/images/ ( 375 px )
 * @param string [href] - Link to the video
 * @param string [target] - Target for the link to the video
 * @param string [href] - Link to the video
 * @returns
 */

const SectionImagePlayer = props => {
  const modeSection = props.mode ?? "white";
  const nameImageHD = props.imageHD ?? "";
  const nameImageSD = props.imageSD ?? nameImageHD;

  if (nameImageHD === "") {
    return <div className="tc red mv5">ERROR props.image empty</div>;
  }

  var srcset = nameImageSD !== "" ? `/images/${nameImageSD} 375w, ` : "";
  srcset += `/images/${nameImageHD}`;

  return (
    <section
      className={`section-image-player ${props.className ?? ""} ${
        modeSection === "dark" ? "sectionDark" : "sectionWhite"
      }`}
    >
      {props.title && <div className="title">{props.title}</div>}
      <WrapperLink href={props.href} target={props.target}>
        <div className={`image-player relative`}>
          <img srcSet={srcset} src={`/images/${nameImageHD}`} className="cover w-100" alt="img" />
          {props.href && <img className="logo" src={"/icons/player.svg"} alt="Logo" />}
        </div>
      </WrapperLink>
    </section>
  );
};

export default SectionImagePlayer;
