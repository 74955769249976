import React from "react";
import "../../css/TitlePage.css";

/**
 * Display the title with the subtitle over
 * @param string [category] (subtitle over the title)
 * @param string title
 * @returns
 */
const TitlePage = props => {
  return (
    <section className="mb4">
      <div className="section-page-title" style={props.style}>
        {props.category && <h2 className="page-category">{props.category}</h2>}
        <h1 className="page-title">{props.title}</h1>
      </div>
    </section>
  );
};

export default TitlePage;
